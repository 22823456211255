// Libs
import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { Progress, Collapse, Table, Dropdown, Menu, Button, Select, Empty } from 'antd';
import Field from 'components/form/field/dynamic/common/Field';
import FieldWrapper from 'components/form/field/field-wrapper';
import ExportModal from 'components/export';

// Icons
import { FilePdfOutlined, DownloadOutlined } from '@ant-design/icons';

// Interfaces
import {
  FormField,
  FormValues,
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
} from "components/form/form-wrapper";
import { DEPENDENCY_OPERATOR_EQUAL, DynamicField } from 'components/form/field/dynamic/Dynamic.interface';
import { RecordFormEntity } from 'types/entities';
import { IDynamicFieldTemplateSet } from 'views/admin/templates/Templates.interfaces';

// Utils
import { findFirst } from 'utils/utils';

// Styles
import './Dynamic.scss';

const { Panel } = Collapse;
const { Option } = Select;

interface Props {
  clientId: number;
  record: RecordFormEntity;
  field: FormField;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
    callback?: () => void,
  ): void;
  originalState: any;
  state: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
  isPreviewing?: boolean;
  getRecord?: (silent?: boolean) => void;
  onFieldChange?: (field: FormField) => void;
};

interface State {
  selectedSetId: string | number | undefined;
  questionSetsHeight: number;
  showPdfExportModal: boolean;
  showCompletedQuestions: boolean;
  showIncompletedQuestions: boolean;
  showQuestionsWithActions: boolean;
  showQuestionsWithoutActions: boolean;
};

class Dynamic extends Component<Props, State> {

  state: State = {
    selectedSetId: _.has(this.props.field, 'dynamic_template_sets') && this.props.field.dynamic_template_sets && !_.isEmpty(this.props.field.dynamic_template_sets) ? this.props.field.dynamic_template_sets[0].id : undefined,
    questionSetsHeight: 200,
    showPdfExportModal: false,
    showCompletedQuestions: false,
    showIncompletedQuestions: false,
    showQuestionsWithActions: false,
    showQuestionsWithoutActions: false,
  };

  componentDidMount = () => {
    this.heightObserver();
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!_.isEqual(prevState.selectedSetId, this.state.selectedSetId)) {
      this.scrollToTop();
    }

    this.heightObserver();
  };

  scrollToTop = () => {
    const dynamicQuestions = document.querySelector('.DynamicQuestions') as HTMLElement;
    if (dynamicQuestions) {
      dynamicQuestions.scrollTop = 0;
    }
  };

  heightObserver = () => {
    const dynamicQuestionSet = document.querySelector('.DynamicQuestionSets') as HTMLElement;

    if (dynamicQuestionSet && this.state.questionSetsHeight !== dynamicQuestionSet?.offsetHeight) {
      const offset = 200; // Add some extra height
      this.setState({
        questionSetsHeight: dynamicQuestionSet?.offsetHeight + offset || offset
      });
    }
  };

  handleChange = (dynamicField: DynamicField, callback?: () => void) => {
    const { field, onChange, config } = this.props;
    onChange(field, this.modifyValues(_.cloneDeep(field.values), dynamicField), config, undefined, () => {
      callback && callback();
    });
  };

  modifyValues = (values: any, newDynamicField: DynamicField) => {
    return values.map((value: any) => {
      const appendChildrenKeys = (children: any, _identifier: number) => {
        return children.map((child: any) => {

          if (child.id === _identifier) {
            child = newDynamicField;
          }

          return {
            ...child,
            'children': !_.isEmpty(child.children) ? appendChildrenKeys(child.children, _identifier) : [],
          };
        });
      };

      if (value.id === newDynamicField.id) {
        value = newDynamicField;
      }

      return {
        ...value,
        'children': !_.isEmpty(value.children) ? appendChildrenKeys(value.children, newDynamicField.id) : [],
      };
    });
  };

  appendKey = (data: any = []) => {
    return data || [].map((entity: any) => {
      const appendChildrenKeys = (children: any) => {

        // Prevent nesting
        if (_.isEmpty(children)) return null;

        return children.map((childEntity: any) => {
          return {
            ...childEntity,
            'key': childEntity.id,
            'children': appendChildrenKeys(childEntity.children),
          };
        });
      };

      return {
        ...entity,
        'key': entity.id,
        'children': appendChildrenKeys(entity.children),
      };
    });
  };

  passedDependencies = (dynamicField: DynamicField, parentDynamicField: DynamicField | null) => {
    const dependencies = dynamicField.dependencies;

    if (dependencies === null || _.isEmpty(dependencies) || parentDynamicField === null) {
      return true;
    }

    const parentDynamicFieldValues: any = parentDynamicField.values != null ? parentDynamicField.values[0] : null;

    return dependencies.every((dependency) => {
      switch (dependency.operator) {
        case DEPENDENCY_OPERATOR_EQUAL:
          const componentMatch = dependency['component_id'] === parentDynamicField.id;
          const valueMatch = parentDynamicFieldValues && dependency['value'] === parentDynamicFieldValues.option_id;

          if (componentMatch && valueMatch) {
            return true;
          }
      }

      return false;
    });
  };

  filterDataset = (dynamicField: DynamicField) => {
    const { showCompletedQuestions, showIncompletedQuestions, showQuestionsWithActions, showQuestionsWithoutActions } = this.state;
    let check = true;

    if (showCompletedQuestions && !!check) {
      check = this.questionCompleted(dynamicField, true);
    }

    if (showIncompletedQuestions && !!check) {
      check = !this.questionCompleted(dynamicField, false);
    }

    if (showQuestionsWithActions && !!check) {
      check = this.questionWithActions(dynamicField, true);
    }

    if (showQuestionsWithoutActions && !!check) {
      check = !this.questionWithActions(dynamicField, false);
    }

    return check;
  };

  questionCompleted = (dynamicField: DynamicField, check: boolean) => {
    let answered = !check;
    if (_.has(dynamicField, 'children') && !_.isEmpty(dynamicField.children)) {
      const children = dynamicField.children;
      children.forEach((child: DynamicField) => {
        if (answered === check) {
          return answered;
        }
        answered = this.questionCompleted(child, check);
      });

      return answered;
    }

    return _.has(dynamicField, 'values') && !_.isEmpty(dynamicField.values);
  };

  questionWithActions = (dynamicField: DynamicField, check: boolean) => {
    let hasAction = !check;
    if (_.has(dynamicField, 'children') && !_.isEmpty(dynamicField.children)) {
      const children = dynamicField.children;
      children.forEach((child: DynamicField) => {
        if (hasAction === check) {
          return hasAction;
        }
        hasAction = this.questionWithActions(child, check);
      });
    }

    if (hasAction === check) {
      return hasAction;
    }

    return _.has(dynamicField, 'action_list') && _.has(dynamicField.action_list, 'data') && !_.isEmpty(dynamicField.action_list.data);
  };

  getFlatten = (data: any[] = []) => {

    const collector: any = [];

    data.forEach((value: any) => {
      const check = (_value: any) => {
        collector.push({ ..._value });

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      return check(value);
    });

    return collector;
  };

  getFlattenedPassedDependencyComponents = (dynamicFields: DynamicField[] = []): DynamicField[] => {

    const collector: DynamicField[] = [];

    dynamicFields.forEach((dynamicField: DynamicField) => {

      const check = (_dynamicField: DynamicField, parentDynamicField: DynamicField | null) => {

        collector.push({ ..._dynamicField });

        if (!_.isEmpty(_dynamicField?.children)) {
          _dynamicField.children.forEach((__dynamicField: DynamicField) => {
            if (this.passedDependencies(_dynamicField, parentDynamicField)) {
              check(__dynamicField, _dynamicField);
            }
          });
        }
      };

      return check(dynamicField, null);
    });

    return collector;
  };

  getParentComponent = (parentComponentId: number, components: FormValues[]) => {
    return findFirst({ children: components }, 'children', { id: parentComponentId });
  };

  isModifiedSet = (originalState: DynamicField[], modifiedState: DynamicField[], dynamicFieldTemplateSet: IDynamicFieldTemplateSet) => {
    return modifiedState
      .filter((field: DynamicField) => {
        return field.template_set_id === dynamicFieldTemplateSet.id;
      })
      .some((field: DynamicField) => {
        const originalField = originalState.find((_dynamicField: DynamicField) => _dynamicField.id === field.id);
        if (originalField) {
          return !_.isEqual(originalField, field);
        }
        return false;
      });
  };

  calculateCompilation = (components: DynamicField[] = []) => {

    const flattenedPassedDependencyComponents = this.getFlattenedPassedDependencyComponents(components)
      .filter((component: DynamicField) => component.type !== 'group');

    const answeredQuestions = flattenedPassedDependencyComponents
      .reduce((prev: any, curr: DynamicField) => {
        if (!_.isEmpty(curr?.values) || !!curr?.not_applicable) {
          return prev + 1;
        }
        return prev;
      }, 0);

    return Math.round(answeredQuestions / flattenedPassedDependencyComponents.length * 100);
  };

  containsRequiredQuestion = (components: DynamicField[] = []) => {
    const flattenedPassedDependencyComponents = this.getFlattenedPassedDependencyComponents(components)
      .filter((component: DynamicField) => component.type !== 'group');

    return flattenedPassedDependencyComponents.find((component: DynamicField) => !!component.config.required);
  };

  handleUpload = (dynamicFieldId: number, fileRecord: any, callback?: () => void) => {
    const { field } = this.props;

    // TODO: Hack to sort referencing issue
    const dynamicField: any = field?.values.find((dynamicField: any) => dynamicField.id === dynamicFieldId);

    if (dynamicField) {
      this.handleChange(_.set(_.cloneDeep(dynamicField), ['attachments', 'files'], dynamicField.attachments.files.concat([fileRecord])), () => {
        callback && callback();
      });
    }
  };

  handleRemove = (dynamicField: DynamicField, file: any) => {
    this.handleChange(_.set(_.cloneDeep(dynamicField), ['attachments', 'files'], dynamicField.attachments.files.filter((_file: any) => _file.file_id !== file.uid)));
  };

  handleComment = (dynamicField: DynamicField, comment: string | null) => {
    this.handleChange(_.set(_.cloneDeep(dynamicField), ['attachments', 'comment'], comment));
  };

  handleCreateAction = (dynamicField: DynamicField) => {
    this.props.onFieldChange && this.props.onFieldChange(_.set(_.cloneDeep(this.props.field), ['values'], this.modifyValues(_.cloneDeep(this.props.field.values), dynamicField)));
  };

  handleScoring = (dynamicField: DynamicField) => {
    this.handleChange(_.cloneDeep(dynamicField));
  };

  renderFields = (field: FormField, selectedSetId: number | string, dynamicFields: DynamicField[], originalState: any[], parentField: DynamicField | null) => {
    return !_.isEmpty(dynamicFields) ? dynamicFields
      .filter((dynamicField: DynamicField) => dynamicField.template_set_id === selectedSetId)
      .filter((dynamicField: DynamicField) => {
        // Prevent empty groups from rendering
        if (dynamicField.type === 'group' && _.isEmpty(dynamicField?.children)) {
          return false;
        }
        return true;
      })
      .filter((dynamicField: DynamicField) => this.filterDataset(dynamicField))
      .filter((dynamicField: DynamicField) => this.passedDependencies(dynamicField, parentField))
      .map((dynamicField: DynamicField, index: number) => {
        const parentComponent = dynamicField?.parent_component_id && this.getParentComponent(dynamicField.parent_component_id, field.values);
        return (
          <div
            key={ index }
            className={ classNames('w-100p', {
              'pL-20': !!parentComponent && parentComponent?.type !== 'group',
              'bdT border-primary': !dynamicField?.parent_component_id,
            }) }
          >
            { dynamicField.type === 'group' ? (
                <FieldWrapper
                  key={ dynamicField.id }
                  label={ dynamicField?.config?.show_label ? dynamicField.label : '' }
                  col={ 12 }
                  border
                >
                  { this.renderFields(field, selectedSetId, dynamicField.children, originalState, dynamicField) }
                </FieldWrapper>
              ) : (
                <>
                  <div>
                    { this.renderField(dynamicField, originalState) }
                  </div>
                  <div>
                    { !_.isEmpty(dynamicField.children) &&
                      this.renderFields(field, selectedSetId, dynamicField.children, originalState, dynamicField)
                    }
                  </div>
                </>
              )
            }
          </div>
        );
    }) : [];
  };

  renderField = (dynamicField: DynamicField, originalState: any[]) => {
    const { clientId, record, field, fieldErrorMessages, fieldModifiedMessages, setFieldModifiedMessage, setFieldErrorMessage, isDisabled, isPreviewing } = this.props;
    return (
      <Field
        clientId={ clientId }
        originalState={ originalState }
        record={ record }
        field={ field }
        dynamicField={ dynamicField }
        fieldErrorMessages={ fieldErrorMessages }
        fieldModifiedMessages={ fieldModifiedMessages }
        isDisabled={ isDisabled }
        isPreviewing={ isPreviewing }
        setFieldModifiedMessage={ setFieldModifiedMessage }
        setFieldErrorMessage={ setFieldErrorMessage }
        onChange={ this.handleChange }
        onComment={ this.handleComment }
        onUpload={ this.handleUpload }
        onRemove={ this.handleRemove }
        onCreateAction={ this.handleCreateAction }
        onScore={ this.handleScoring }
      />
    );
  };

  render = () => {
    const { clientId, field, record, state, originalState, isPreviewing } = this.props;
    const { selectedSetId, questionSetsHeight, showPdfExportModal } = this.state;
    const hasSets = !_.isEmpty(field?.dynamic_template_sets);

    return (
      <FieldWrapper col={ 12 }>
        <Collapse
          expandIconPosition="left"
          defaultActiveKey={ [1] }
        >
          <Panel
            key={ 1 }
            header={ (
              <span
                className={ classNames({
                  'text-warning': !_.isEqual(originalState, state)
                }) }
              >
                { _.has(field, 'template.title') ? field.template.title : '' }
              </span>
            ) }
            extra={ !isPreviewing ? (
              <div className="d-f" onClick={ (e: React.BaseSyntheticEvent) => e.stopPropagation() }>
                <span className="mR-5" >{ `Filters` }</span>
                <Select
                  allowClear
                  style={{ width: 200 }}
                  className="mR-5"
                  key={ 'questionCompletionFilter' }
                  placeholder={ 'Question Completion' }
                  dropdownMatchSelectWidth={ false }
                  onChange={ (value: string) => {
                    this.setState({
                      showCompletedQuestions: value === 'showCompletedQuestions',
                      showIncompletedQuestions: value === 'showIncompletedQuestions'
                    });
                  } }
                >
                  <Option value={ 'showCompletedQuestions' }>Completed Questions</Option>
                  <Option value={ 'showIncompletedQuestions' }>Incomplete Questions</Option>
                </Select>
                <Select
                  allowClear
                  style={{ width: 200 }}
                  className="mR-5"
                  key={ 'questionsActions' }
                  placeholder={ 'Questions Actions' }
                  dropdownMatchSelectWidth={ false }
                  onChange={ (value: string) => {
                    this.setState({
                      showQuestionsWithActions: value === 'showQuestionsWithActions',
                      showQuestionsWithoutActions: value === 'showQuestionsWithoutActions'
                    });
                  } }
                >
                  <Option value={ 'showQuestionsWithActions' }>Questions With Actions</Option>
                  <Option value={ 'showQuestionsWithoutActions' }>Questions Without Actions</Option>
                </Select>
                <Dropdown
                  overlay={ (
                    <Menu>
                      <Menu.Item
                        key="pdf"
                        onClick={ () => {
                          this.setState({
                            showPdfExportModal: true
                          });
                        } }
                      >
                        <FilePdfOutlined className="mR-5" />
                        PDF
                      </Menu.Item>
                    </Menu>
                  ) }
                  trigger={ ['click'] }
                >
                  <Button
                    className="mR-5"
                  >
                    <DownloadOutlined />
                    Export
                  </Button>
                </Dropdown>
              </div>
            ) : undefined }
          >
            <div className={ `d-f ${hasSets ? 'fxd-r' : 'fxd-c'}` }>
              { hasSets ? (
                <>
                  <div
                    className="DynamicQuestionSets d-if mR-10 bd"
                    style={{ width: 300, minHeight: 150 }}
                  >
                    <Table
                      showHeader={ false }
                      className="w-100p"
                      dataSource={ _.has(field, 'dynamic_template_sets') && field.dynamic_template_sets ? field.dynamic_template_sets
                        .filter((set: IDynamicFieldTemplateSet) => {
                          return field.values.filter((value: any) => value.template_set_id === set.id || value.template_set_id === set.key);
                        })
                        .map((set: IDynamicFieldTemplateSet) => {
                          return {
                            'key': set.id || set.key,
                            ...set
                          };
                        }) : []
                      }
                      pagination={ false }
                      onRow={ (__, index): any => ({ index }) }
                      rowClassName={ (item: any) => {
                        return selectedSetId && selectedSetId === item.id ? 'ant-table-row-selected' : '';
                      } }
                      locale={ {
                        emptyText: () => <>Categories</>
                      } }
                      columns={ [
                        {
                          title: '',
                          dataIndex: 'title',
                          render: (__: any, set: IDynamicFieldTemplateSet) => {
                            const components = state.filter((value: any) => {
                              return value.template_set_id === set.id || value.template_set_id === set.key;
                            });
                            return (
                              <div
                                className="d-f jc-sb cur-p"
                                onClick={ () => this.setState({ selectedSetId: set.id || set.key }) }
                              >
                                <div className="w-100p">
                                  <div
                                    className={ classNames({
                                      'text-warning': this.isModifiedSet(originalState, state, set)
                                    }) }
                                  >
                                    { set.title }
                                    { this.containsRequiredQuestion(components) &&
                                      <span className="text-required">*</span>
                                    }
                                  </div>
                                  <div>
                                    <Progress percent={ this.calculateCompilation(components) } />
                                  </div>
                                </div>
                              </div>
                            );
                          },
                        }
                      ] }
                    />
                  </div>
                  <div
                    className="DynamicQuestions w-100p ovY-s"
                    style={{ height: questionSetsHeight, minHeight: 'calc(100vh - 300px)' }}
                  >
                    { !!selectedSetId && this.renderFields(field, selectedSetId, !_.isEmpty(field.values) && this.appendKey(field.values), this.getFlatten(this.appendKey(originalState)), null) }
                  </div>
                </>
              ) : (
                <div className="w-100p">
                  <Empty
                    image={ Empty.PRESENTED_IMAGE_SIMPLE }
                    description={ 'No Questions Available' }
                  />
                </div>
              ) }
              { showPdfExportModal &&
                <ExportModal
                  clientId={ clientId }
                  modalTitle={ 'PDF Export' }
                  exportEndpoint={ `client/${clientId}/field/dynamic/audit_questions/export-pdf?entity_id=${record.id}&entity_type=${record.type}&entity_bundle=${record.bundle}` }
                  onClose={ () => this.setState({ showPdfExportModal: false }) }
                  content={ 'Please click the "Export" button below to begin the export. Your file will be generated by our servers and downloadable as soon as it is ready. Note, this could take a few minutes depending on the size of your file.' }
                />
              }
            </div>
          </Panel>
        </Collapse>
      </FieldWrapper>
    );
  };
};

export default Dynamic;